@import "../inc/accordians";

section.alternate-services,
section.extra-information
{
    background-color: $sel-color-7-fade;
    padding: 2rem 0 6.25rem;
    margin: 0 0 -3rem;

    @include media-breakpoint-down(md)
    {
        margin-bottom: 5rem;
    }

    > h2
    {
        @extend %contained-item;
        font-size: 1.875rem;
        color: $sel-color-6;
        line-height: 1.5rem;
        text-align: center;
        margin: 2rem auto .5rem;
    }

    > h3
    {
        @extend %base-text-size;
        @extend %contained-item;
        color: $colorAccent;
        line-height: 1.5rem;
        text-align: center;
        margin: 0 auto 2rem;
    }

    details
    {
        @extend %accordians;
        @extend %contained-item;
        background-color: $colorInverse;
    }

    .waiting-times-updated
    {
        @extend %contained-item;
    }



    > footer
    {
        padding: 1.25rem 12.5% 0;
        text-align: center;

        > p
        {
            font-size: .75rem;
            line-height: 1.25rem;
            margin-bottom: .333rem;

            &:last-child
            {
                margin-bottom: 0;
            }

            a
            {
                color: $sel-color-6;
                text-decoration: none;
                font-weight: bold;
            }
        }
    }
}

section.extra-information
{
    background-color: $colorInverse;

    details
    {
        background-color: $sel-color-7-fade;
    }
}

p.alternative-services-link
{
    text-align: center;
    margin: 1.75rem 0;

    > a
    {
        font-weight: bold;
        color: #00a8d7;
        text-decoration: none;

        > span
        {
            text-decoration: underline;
        }
    }
}
