@import "inc/variables";
@import "inc/bootstrap/defer";

@import "inc/helpers";

$la-css-prefix: la;
@import "~line-awesome/dist/line-awesome/scss/line-awesome";
@import "~font-awesome/scss/font-awesome";
@import "~jssocials/styles/jssocials";
@import "~jssocials/styles/jssocials-theme-flat";

html
{
    background-color: $colorAccent;
}

body
{
    background-color: $colorInverse;
}

@import "inc/colorbox";
@import "inc/la-fix";

@import "parts/alternate-services";